import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps" //npm install --save react-google-maps
//https://tomchentw.github.io/react-google-maps/
import Swal from 'sweetalert2'; //npm install --save sweetalert2
import withReactContent from 'sweetalert2-react-content';
import { Fragment } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
const MySwal = withReactContent(Swal)

//const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBjoimOQOcqD_5K2JwOR-3d3WlDguKmxmM&v=3.exp&libraries=geometry,drawing,places";
/*const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCe3gBSL4p1HctLMiVgGw1aYEcs2BKJYpw&v=3.exp&callback=Function.prototype&libraries=geometry,drawing,places";*/
const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBa4D_Tp8ccHiYJWSO2S0-_kN35TBd6Qxw&v=3.exp&callback=Function.prototype&libraries=geometry,drawing,places";

const loadingElement = <div style={{ height: `100%` }} />;
const containerElement = <div style={{ height: `100%` }} />;
const mapElement = <div style={{ height: `100%` }} />;
const position = { of: '#historydiv' };


export class LiveTrackingComponent extends Component {
    static displayName = LiveTrackingComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            loadPanel: false,
            isSubmited: false,
            rideId: this.props.location.state.rideId == 0 ? 0 : this.props.location.state.rideId,
            trackingData: [],
           // data: this.props.location.state.trackingData,
            errors: {
                userName: '',
            }
        }
        this.interval = null;
        this.getTrackingData = this.getTrackingData.bind(this);
        this.refreshMap = this.refreshMap.bind(this);
        this.goToTracking = this.goToTracking.bind(this);
        this.multipleVehicleMap = React.createRef();
    }

    componentDidMount() {
        this.getTrackingData();
        setInterval(this.getTrackingData, 30000);
    }

    async refreshMap() {
        window.location.reload(false);
    }

    async getTrackingData() {
        try {
            this.setState({ loadPanel: true });
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            var request = new Object();
            request.FlightNo = this.state.rideId == 0 ? parseInt(parsed.rideId) : this.state.rideId;

            const requestParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            }
            let vehicleId = 0;
            const response = await fetch('tracking-api/track-vehicle', requestParams);
            const data = await response.json();
            debugger;
            let trackingData = data.trackVehicles == null ? [] : data.trackVehicles;
            this.setState({
                trackingData: trackingData,
                loadPanel: false
            });


            if (this.state.trackingData.length <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Locate Vehicle</small>",
                    text: "Unable to find GPS location of vehicle.",
                    icon: "success",
                    width: '45%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async goToTracking() {        
    }

    render() {
        return (
            <Fragment>
                {this.state.trackingData.length >0 ? <div className="driver-status">{this.state.trackingData[0].tripStatus}</div> : ''}
                <div className="map-container">
                    {this.state.trackingData.length == 0 && <DefaultVehicleMap refreshMap={this.refreshMap} />}
                    {(this.state.trackingData.length > 0) && <SingleVehicleMap vehicleDetails={this.state.trackingData} loadPanel={this.state.loadPanel} refreshMap={this.refreshMap} />}
                </div>
            </Fragment>
        );
    }
}

const DefaultVehicleMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const refs = {}
            this.setState({

            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
        defaultOptions={{
            mapTypeControl: true,
            streetViewControl: true,
            zoomControl: true,
            fullscreenControl: false,
            minZoom: 2,
            maxZoom: 18,
            gestureHandling: "greedy",
            disableDefaultUI: true
        }}
    >
        {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
        <div className="btn-refresh"><button type="button" className="btn btn-primary" onClick={props.refreshMap}> Refresh</button></div>
    </GoogleMap>
);

const SingleVehicleMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const refs = {}
            const DirectionsService = new window.google.maps.DirectionsService();
            var start = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].latitude), parseFloat(this.props.vehicleDetails[0].longitude));
            var end = "";
            if (this.props.vehicleDetails[0].statusId == 108) {
                end = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].dropOffLatitude), parseFloat(this.props.vehicleDetails[0].dropOffLongitude));
            } else {
                end = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].pickupLatitude), parseFloat(this.props.vehicleDetails[0].pickupLongitude));
            }

            //google.maps.event.addListenerOnce(yourMap, 'bounds_changed', function (event) {
            //    if (this.getZoom() > 15) {
            //        this.setZoom(15);
            //    }
            //});
            DirectionsService.route({
                origin: start,
                destination: end,
                travelMode: window.google.maps.TravelMode.DRIVING,
                durationInTraffic: true,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                        eta: result.routes[0].legs[0].duration_in_traffic.text,
                    });

                    this.setState({
                        bounds: null,
                        showInfoIndex: 0,
                        onMapMounted: ref => {
                            refs.map = ref;
                            //console.log("Zoom to markers");
                            const bounds = new window.google.maps.LatLngBounds();
                            ref.props.children.forEach((child) => {
                                if (child.type === Marker) {
                                    bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                                }
                            })
                            // ref.setMap(null)
                            ref.fitBounds(bounds);
                        },
                        onBoundsChanged: () => {
                            this.setState({
                                bounds: refs.map.getBounds(),
                                center: refs.map.getCenter(),
                            })
                        },
                        showInfo: (a) => {
                            this.setState({ showInfoIndex: a })
                        },
                        handleCloseCall: () => {
                            this.setState({ showInfoIndex: '' })
                        },
                        showAddressInfo: (a) => {
                            this.setState({ showAddessWindow: true })
                        },
                        handleCloseAddress: (a) => {
                            this.setState({ showAddessWindow: false })
                        },
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <Fragment>        
        <div className="map">
            <GoogleMap
                ref={props.onMapMounted}
                defaultZoom={15}
                defaultOptions={{
                    mapTypeControl: true,
                    streetViewControl: true,
                    zoomControl: true,
                    fullscreenControl: false,
                    minZoom: 2,
                    maxZoom: 18,
                    gestureHandling: "greedy"
                }}
                defaultCenter={{ lat: parseFloat(props.vehicleDetails[0].latitude), lng: parseFloat(props.vehicleDetails[0].longitude) }}
                onBoundsChanged={props.onBoundsChanged}
            >

                {props.vehicleDetails[0].statusId == 108 && <Marker
                    position={{ lat: parseFloat(props.vehicleDetails[0].dropOffLatitude), lng: parseFloat(props.vehicleDetails[0].dropOffLongitude) }}
                    onClick={() => { props.showAddressInfo() }}
                >
                    {(props.showAddessWindow) &&
                        <InfoWindow onCloseClick={props.handleCloseAddress} options={{ maxWidth: 100 }} >
                            <div style={{ paddingBottom: 3 + 'px' }}>
                            <span>{props.vehicleDetails[0].dropoffAddress}</span>
                            </div>
                        </InfoWindow>}
                </Marker>}

                {props.vehicleDetails[0].statusId != 108 && <Marker
                    position={{ lat: parseFloat(props.vehicleDetails[0].pickupLatitude), lng: parseFloat(props.vehicleDetails[0].pickupLongitude) }}
                    onClick={() => { props.showAddressInfo() }}
                >
                    {(props.showAddessWindow) &&
                        <InfoWindow onCloseClick={props.handleCloseAddress} options={{ maxWidth: 100 }} >
                            <div style={{ paddingBottom: 3 + 'px' }}>
                            <span>{props.vehicleDetails[0].pickupAddress}</span>
                            </div>
                        </InfoWindow>}
                </Marker>}

                {props.vehicleDetails.map((marker, index) =>
                    <Marker
                        key={index}
                        position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                        icon={{
                            url: "https://member.utwiz.com/TrackVehicle/Car/0.png",
                            anchor: new window.google.maps.Point(20, 20),
                            scaledSize: new window.google.maps.Size(30, 30)
                        }}
                        onClick={() => { props.showInfo(index) }}
                    >
                    </Marker>
                )}

                {props.directions && <DirectionsRenderer directions={props.directions} options={{
                    polylineOptions: {
                        strokeOpacity: 0.5,
                        //strokeColor: '#FF0000',
                    },
                    suppressMarkers: true

                }} />}
                <div className="btn-refresh">
                    <button type="button" className="btn btn-primary" onClick={props.refreshMap}> Refresh</button>
                </div>
            </GoogleMap>
        </div>
        <div>
            {props.vehicleDetails != null && props.vehicleDetails.map((item, j) =>
                <div className="info-driver" key={j}>
                    <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                        <tr>
                            <td className="photo-driver" width="50px"><img src={item.imageURL} width="50px" height="50px" /></td>
                            <td className="info-driver-vehicle" width="120px">
                                <span className="value">{item.driverName}</span><br />
                                <span className="title">{item.vehicleNo}</span><span className="divider">•</span><span className="title">{item.vehicleType}</span><br />
                                <span className="title">{item.statusId <= 107 ? "ETA" : "ETD"}</span><span className="title">{props.eta}</span>
                            </td>
                            <td className="ico-call" align="right"><a href={"tel:+" + item.driverMobile}><img
                                src="https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/accept-call-icon.png"
                                width="50px" height="50px" /></a></td>
                        </tr>
                    </table>
                </div>
            )}
        </div>
        {/*<LoadPanel*/}
        {/*    shadingColor="rgba(0,0,0,0.4)"*/}
        {/*    position={position}*/}
        {/*    visible={this.props.loadPanel}*/}
        {/*    showIndicator={true}*/}
        {/*    shading={false}*/}
        {/*    showPane={true}*/}
        {/*    closeOnOutsideClick={false}*/}
        {/*/>*/}
    </Fragment>
);


